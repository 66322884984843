import { updateMachineCart } from "../utilities";

export const useMachineCartItemUpdate = (code: string) => {
    return async (quantity: number) => {
        await updateMachineCartItem(code, quantity);
    };
}

export const updateMachineCartItem = async (code: string, quantity: number) => {
    await updateItem(code, quantity);
    await updateMachineCart();
}

const updateItem = async (code: string, quantity: number) => {
    const response = await fetch(`/api/machinesales/cart/items/update`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
        },
        body: JSON.stringify({ code, quantity }),
        method: "POST"
    });
    const data = await response.json();
    if (!data.isSuccess) {
        console.error(JSON.stringify(data))
    }
}