import {useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import * as styles from './FinancePopUpBody.module.scss';
import {FinanceOptions} from '../../types';
import {InputCheckbox, InputNumber, InputSelect, InputText} from '../Inputs';
import {debounce, setPopupButton} from '../../utilities';
import {UpdateFinanceOptions} from "finance-button/src/types";

const translations = (window as any).app.preloadState.translation;
const userId: string = (window as any).app.preloadState.currentUserId;

type FinanceApiData = {
    currency: string;
    unitPriceAmount?: number;
    unitPriceTaxValue?: number,
    unitPriceTaxValueWithCurrency?: string,
    financeOptionsDescription: string,
    estimatedAnnualPercentageRate: number,
    availableTermsOptions: number[],
    availableDownpaymentOptions: number[],
    downpaymentPercentage: number,
    insuranceCost: number,
    insuranceCostWithCurrency?: string,
    shipmentCost: number,
    shipmentCostWithCurrency: string,
    isInsuranceVisible: boolean,
    quantity: number,
    selectedTerm: number | null,
    selectedDownpayment: number | null
};

type FinancePopupData = {
    Currency: string;
    UnitPriceAmount?: number;
    UnitPriceTaxValue?: number;
    UnitPriceTaxValueWithCurrency?: string;
    FinanceOptionsDescription: string,
    EstimatedAnnualPercentageRate: number,
    AvailableTermsOptions: { label: string, value: number }[],
    SelectedTerm: number,
    Downpayment: number,
    AvailableDownpaymentOptions: { label: string, value: number }[],
    SelectedDownpayment: number,
    InsuranceCost: number,
    InsuranceCostWithCurrency?: string,
    IncludeTaxesAndFees: boolean,
    ShipmentCost: number,
    ShipmentCostWithCurrency: string,
    isInsuranceVisible: boolean,
    Quantity: number
};

type FinanceOptionsChoicesData = Partial<Record<string, FinanceOptionsUserChoices>>;

type FinanceOptionsUserChoices = Partial<Record<string, FinanceOptionsMachineChoices>>;

type FinanceOptionsMachineChoices = Partial<{
    SelectedDownpayment: number;
    SelectedTerm: number;
    IncludeTaxesAndFees: boolean;
}>;

export function FinancePopUpBody({serialNumber, readonly = false, onChange}: {
    serialNumber: string,
    readonly?: boolean,
    onChange?: (options: UpdateFinanceOptions) => void
}) {
    const [state, setState] = useState<FinancePopupData | null>(null);

    const getLocalChoices = () => {
        try {
            const choicesJson: string | null = window.localStorage.getItem("financeOptionsChoices");
            if (!choicesJson) return {};
            const choices: FinanceOptionsChoicesData = JSON.parse(choicesJson);
            if (!choices || typeof choices !== 'object') return {};
            const userChoices: FinanceOptionsUserChoices | undefined = choices[userId];
            if (!userChoices || typeof userChoices !== 'object') return {};
            const machineChoice: FinanceOptionsMachineChoices | undefined = userChoices[serialNumber];
            if (!machineChoice || typeof machineChoice !== 'object') return {};
            return machineChoice;
        } catch {
            return {};
        }
    };

    useEffect(() => {
        if (!state) return;
        const choicesJson: string | null = window.localStorage.getItem("financeOptionsChoices");
        let choices: FinanceOptionsChoicesData;
        try {
            choices = JSON.parse(choicesJson ?? "{}");
            if (!choices || typeof choices !== 'object') {
                choices = {};
            }
        } catch {
            choices = {};
        }
        let userChoices: FinanceOptionsUserChoices = choices[userId] ?? {};
        if (!userChoices || typeof userChoices !== 'object') {
            userChoices = {};
        }
        let machineChoices: FinanceOptionsMachineChoices = userChoices[serialNumber] ?? {};
        if (!machineChoices || typeof machineChoices !== 'object') {
            machineChoices = {};
        }

        machineChoices = {
            SelectedDownpayment: state.SelectedDownpayment,
            SelectedTerm: state.SelectedTerm,
            IncludeTaxesAndFees: state.IncludeTaxesAndFees
        };

        userChoices[serialNumber] = machineChoices;
        choices[userId] = userChoices;

        if (machineChoices.SelectedDownpayment === 0 && machineChoices.SelectedTerm === 0 && machineChoices.IncludeTaxesAndFees === true) {
            delete userChoices[serialNumber];
            if (Object.keys(userChoices).length === 0) {
                delete choices[userId];
            }
        }

        window.localStorage.setItem("financeOptionsChoices", JSON.stringify(choices));
    }, [state]);

    const [resultState, setResultState] = useState<FinanceOptions>({
        financedAmount: 0,
        financedAmountWithCurrency: "",
        monthlyAnnuityPayment: 0,
        monthlyPayment: 0,
        monthlyPaymentWithCurrency: "",
        totalCost: 0,
        firstInstalment: 0,
        firstInstalmentWithCurrency: ""
    });

    const debouncedRecalculate = useMemo(() => debounce(async (state: FinancePopupData) => {
        const response = await fetch('/api/machinesales/financeOptions/recalculate', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                currency: state.Currency,
                price: (state.UnitPriceAmount || 0) * (state.Quantity || 1),
                downPaymentOption: state.SelectedDownpayment,
                finalPayment: 0,
                yearlyInterestRatePercentage: state.EstimatedAnnualPercentageRate,
                numberOfMonthsOnTerm: state.SelectedTerm,
                insuranceAmount: state.InsuranceCost || 0
            }),
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const data: FinanceOptions = await response.json();
        setResultState(data);

        let financeOptions: UpdateFinanceOptions = {
            code: serialNumber,
            quantity: state.Quantity,
            financeOptionsInput: {
                currency: state.Currency,
                price: (state.UnitPriceAmount || 0) * (state.Quantity || 1),
                downPaymentOption: state.SelectedDownpayment,
                yearlyInterestRatePercentage: state.EstimatedAnnualPercentageRate,
                numberOfMonthsOnTerm: state.SelectedTerm,
                insuranceAmount: state.InsuranceCost,
            }
        };
        onChange && onChange(financeOptions);
    }, 500), []);

    useEffect(() => {
        const localChoices = getLocalChoices();
        (async () => {
            const response = await fetch(`/api/machinesales/financeOptions/${serialNumber}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (!response.ok) {
                throw new Error(await response.text());
            }
            const data: FinanceApiData = await response.json();
            setState({
                Currency: data.currency,
                UnitPriceAmount: data.unitPriceAmount,
                UnitPriceTaxValue: data.unitPriceTaxValue,
                UnitPriceTaxValueWithCurrency: data.unitPriceTaxValueWithCurrency,
                FinanceOptionsDescription: data.financeOptionsDescription,
                EstimatedAnnualPercentageRate: data.estimatedAnnualPercentageRate,
                AvailableTermsOptions: data.availableTermsOptions && data.availableTermsOptions.map(term => ({
                    label: term + ' ' + translations["financeOptions.months"],
                    value: term
                })),
                SelectedTerm: localChoices.SelectedTerm ?? (data.selectedTerm ? data.selectedTerm : data.availableTermsOptions[0]),
                AvailableDownpaymentOptions: data.availableDownpaymentOptions.map(downpayment => ({
                    label: downpayment + ' %',
                    value: downpayment
                })),
                SelectedDownpayment: localChoices.SelectedDownpayment ?? (data.selectedDownpayment ? data.selectedDownpayment : data.availableDownpaymentOptions[0]),
                Downpayment: (data.unitPriceAmount || 0) * data.availableDownpaymentOptions[0] / 100,
                InsuranceCost: data.insuranceCost,
                InsuranceCostWithCurrency: data.insuranceCostWithCurrency,
                IncludeTaxesAndFees: localChoices.IncludeTaxesAndFees ?? true,
                ShipmentCost: data.shipmentCost,
                ShipmentCostWithCurrency: data.shipmentCostWithCurrency,
                isInsuranceVisible: data.isInsuranceVisible,
                Quantity: data.quantity,
            });
        })();
    }, []);


    useEffect(() => {
        if (state && state.Downpayment >= 0 && state.InsuranceCost >= 0 && state.Downpayment < (state.UnitPriceAmount ?? 0)) {
            !readonly && setPopupButton(0, true);
            debouncedRecalculate(state);
        } else !readonly && setPopupButton(0, false);
    }, [state]);

    const changeState = (name: keyof FinancePopupData) => (value: FinancePopupData[typeof name]) => {
        if (state) {
            setState({
                ...state,
                [name]: value
            });
        }
    };

    return <div>
        <div className={styles.row}>
            <div>
                {state?.FinanceOptionsDescription ?? ""}
            </div>
        </div>

        <div className={styles.row}>
            <div>
                <InputSelect
                    name="downpayment"
                    label={translations["financeOptions.downpayment"]}
                    value={state?.SelectedDownpayment ?? 0}
                    disabled={readonly}
                    onChange={changeState('SelectedDownpayment')}
                    options={state?.AvailableDownpaymentOptions ?? []}
                />
            </div>
            <div>
                <InputSelect
                    name="term"
                    label={translations["financeOptions.term"]}
                    value={state?.SelectedTerm ?? 0}
                    disabled={readonly}
                    onChange={changeState('SelectedTerm')}
                    options={state?.AvailableTermsOptions ?? []}
                />
            </div>
        </div>
        <div className={styles.row}>
            {state?.isInsuranceVisible && <div>
                <InputText
                    name="insurance"
                    label={translations["financeOptions.insurance"]}
                    value={state?.InsuranceCostWithCurrency}
                    disabled={true}
                    error={(state?.InsuranceCost ?? 1) < 0
                        ? translations["financeOptions.valueCannotBeLowerThanZero"]
                        : undefined}>
                    <span className={styles.iconForHoveredText}
                          title={translations["financeOptions.insuranceInfoIconTitle"] ?? ""}></span>
                </InputText>
            </div>}
            <div>
                <InputNumber
                    name="apr"
                    label={translations["financeOptions.apr"]}
                    value={state?.EstimatedAnnualPercentageRate ?? 0}
                    disabled={true}
                >
                    <div className="user-select-none">％</div>
                </InputNumber>
            </div>
        </div>

        <div className={styles.row}>
            <div>
                <InputText
                    name="financedAmount"
                    label={translations["financeOptions.financedAmount"]}
                    value={resultState?.financedAmountWithCurrency}
                    disabled={true}/>
            </div>
            <div>
                <InputText
                    name="firstPaymentCost"
                    label={translations["financeOptions.firstPaymentCost"]}
                    value={resultState?.firstInstalmentWithCurrency}
                    disabled={true}/>
            </div>
        </div>
        <div className={cn(styles.row, styles.moreMargin)}>
            <InputCheckbox name="includeTaxesAndFees" label={translations["financeOptions.includeTaxesAndFees"]}
                           disabled={readonly} checked={state?.IncludeTaxesAndFees}
                           onChange={changeState('IncludeTaxesAndFees')}/>
        </div>

        <div className={cn(styles.row, styles.moreMargin, styles.totalPayment)}>
            <div>{translations["financeOptions.estimatedLeasePayment"]}</div>
            <div>{resultState.financedAmountWithCurrency}</div>
        </div>
        <div className={styles.row}>
            <div>{translations["financeOptions.monthlyCost"]}</div>
            <div>{resultState.monthlyPaymentWithCurrency}</div>
        </div>
        <div className={styles.row}>
            <div>{translations["financeOptions.firstInstallment"]}</div>
            <div>{resultState.firstInstalmentWithCurrency}</div>
        </div>
        {(state?.IncludeTaxesAndFees === true) && (<>
            <div className={styles.row}>
                <div>{translations["financeOptions.shippingFees"]}</div>
                <div>{state?.ShipmentCostWithCurrency}</div>
            </div>
            <div className={styles.row}>
                <div>{translations["financeOptions.taxes"]}</div>
                <div>{state.UnitPriceTaxValueWithCurrency}</div>
            </div>
        </>)}
    </div>
}
