import { MachineCart } from '../types';
import { Observable } from './observable';
import { useEffect } from 'react';

declare global {
    interface Window {
        machinecart: Observable<MachineCart>,
    }
}

export const ensureMachineCart = () => {
    if (window.machinecart) return;

    window.machinecart = Observable(async () => {
        const response = await fetch('/api/machinesales/cart', {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const data = await response.json() as MachineCart;

        return data;
    })
}

export const useMachineCartChange = (callback: (cart: MachineCart) => void) => {
    ensureMachineCart();
    useEffect(() => {
        window.machinecart.subscribe(callback);
        return () => window.machinecart.unsubscribe(callback);
    }, [callback]);
}

export const updateMachineCart = () => window.machinecart?.update();
