// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E9UOGP1zTH9znR7MievH{display:flex;gap:72px;margin-bottom:8px;justify-content:space-between}@media(max-width: 768.02px){.E9UOGP1zTH9znR7MievH{flex-direction:column;gap:8px}}.E9UOGP1zTH9znR7MievH>*{flex:1 0 0}.E9UOGP1zTH9znR7MievH>*:nth-child(2){text-align:right}.fmdOy6orRa36lvGiw8OR{margin-top:16px}.UuS9AyzO4f17_vigsATh{font-weight:bold}@media(min-width: 0){.UuS9AyzO4f17_vigsATh{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 400px){.UuS9AyzO4f17_vigsATh{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 768px){.UuS9AyzO4f17_vigsATh{font-size:1.8rem;line-height:2.8rem}}@media(min-width: 1366px){.UuS9AyzO4f17_vigsATh{font-size:1.9rem;line-height:2.8rem}}.pwtSA3CeupjhqFcqOSie:after{width:24px;height:24px;display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Help-tip-gray.svg")}.pwtSA3CeupjhqFcqOSie{cursor:pointer;width:24px;height:24px;display:block}.pwtSA3CeupjhqFcqOSie:after{background-size:24px 24px}`, ""]);
// Exports
export var row = `E9UOGP1zTH9znR7MievH`;
export var moreMargin = `fmdOy6orRa36lvGiw8OR`;
export var totalPayment = `UuS9AyzO4f17_vigsATh`;
export var iconForHoveredText = `pwtSA3CeupjhqFcqOSie`;
export default ___CSS_LOADER_EXPORT___;
